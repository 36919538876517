import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosToken from "api/axiosToken";
import axios from "api/axios";
import MDSnackbar from "components/MDSnackbar";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ValidarLoja() {
  const navigate = useNavigate();
  const urlParameters = useParams();
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [titulo, setTitulo] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");

  const buttonStyle = {
    background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
    color: "white",
    padding: "6px",
    border: "none",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const getStoreInformation = () => {
    axiosToken.get(`/lojas/${urlParameters.id}`).then((res) => {
      setValues({ ...res.data });
    });
  };

  useEffect(() => {
    getStoreInformation();
  }, []);

  const [values, setValues] = useState({
    razaoSocial: "",
    nomeFantasia: "",
    nomeResponsavel: "",
    numeroContato: "",
    inscricaoEstadual: "",
    imagem: "",
    categoria: {},
    endereco: {},
    categoriaId: "",
    regraDePontos: "",
    documentoComprovacao: "",
    latitude: "",
    longitude: "",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  const lojaValidada = (e) => {
    e.preventDefault();
    axiosToken
      .put(`admins/ativarloja/${urlParameters.id}`)
      .then((res) => {
        setShowSucess(true);
        setSucessMessage("Loja validada com sucesso");
        navigate("/ValidarLojas");
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage("Erro ao validar loja", err.message);
      });
  };

  const negarLoja = (e) => {
    e.preventDefault();
    axiosToken
      .put(`admins/negar/${urlParameters.id}`)
      .then((res) => {
        axios.post("/pushnotification", {
          tokenCelular: [values.usuario.tokenCelular],
          title: titulo,
          body: mensagem
        }).then((res) => {
          console.log(res);
          setShowSucess(true);
          setSucessMessage("Resposta enviada");
          navigate("/ValidarLojas");
        }).catch((err) => {
          setShowError(true);
          console.log(err);
          setErrorMessage("Ouve um erro ao enviar a respota", err.message);
        });
      }).catch((err) => {
        setShowError(true);
        console.log(err);
        setErrorMessage("Ouve um erro ao enviar a respota", err.message);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br></br>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Validação de Lojas
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="RazaoSocial"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={values.razaoSocial}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="nomeFantasia"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.nomeFantasia}
                fullWidth
              />
            </MDBox>
            {values?.cnpj && (
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="CNPJ"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={values.cnpj}
                  fullWidth
                />
              </MDBox>
            )}
            {values?.cpf && (
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="CPF"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={values.cpf}
                  fullWidth
                />
              </MDBox>
            )}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="nomeResponsavel"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.nomeResponsavel}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="numeroContato"
                variant="standard"
                value={values.numeroContato}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="inscricaoEstadual"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.inscricaoEstadual}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <a
                style={buttonStyle}
                href={values.imagem}
                download
                target="_blank"
              >
                Visualizar Logo
              </a>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="categoriaID"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.categoria ? values.categoria.id : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="regraDePontos"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.regraDePontos}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <a
                style={buttonStyle}
                href={values.documentoComprovacao}
                download
                target="_blank"
              >
                Visualizar Documento
              </a>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="latitude"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.latitude : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="longitude"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.longitude : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="cep"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.cep : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="logradouro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.logradouro : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="complemento"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.complemento : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="bairro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.bairro : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="cidade"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.cidade : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="uf"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.uf : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={lojaValidada}
                fullWidth
              >
                Validar
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => handleOpen()}
                fullWidth
              >
                Negar Validação
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center"></MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Enviar Menssagem
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Titulo"
                onChange={(e) => setTitulo(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Mensagem"
                onChange={(e) => setMensagem(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              onClick={negarLoja}
            >
              Negar loja
            </MDButton>
          </MDBox>
        </Box>
      </Modal>
      <MDSnackbar
        open={showError}
        title="ERROR"
        content={errorMessage}
        onClose={closeErrorNotification}
        close={closeErrorNotification}
        color="error"
        icon="warning"
      ></MDSnackbar>
      <MDSnackbar
        open={showSucess}
        title="OK!"
        content={sucessMessage}
        onClose={closeSucessNotification}
        close={closeSucessNotification}
        color="success"
        icon="warning"
      ></MDSnackbar>
    </DashboardLayout>
  );
}

export default ValidarLoja;
