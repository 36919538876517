// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React from "react";
import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import axiosToken from "api/axiosToken";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import lojaTableData from "layouts/lojas/data/lojaTableData";

function Lojas() {
  const { columns, rows } = lojaTableData();



  const [searchQuery, setSearchQuery] = useState("");

  function search(rows) {
    return rows.filter((row) => {
      return (
        row.author.props.name.toLowerCase().includes(searchQuery) ||
        row.author.props.email.toLowerCase().includes(searchQuery)
      );
    });
  }
  console.log(rows);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Lojas
                  <MDInput
                    style={{
                      marginLeft: "2%",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                    type="text"
                    label="Pesquisar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                  />
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: search(rows) }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>

          </Grid>
          
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lojas;
