/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import ValidarLojas from "layouts/validarLojas";
import Banners from "layouts/banners";
// import RTL from "layouts/rtl";
import Lojas from "layouts/lojas";
import Usuarios from "layouts/usuarios";
import SignIn from "layouts/authentication/sign-in";
import CadastrarAdmin from "layouts/cadastrarAdmin/sign-up";
import Categorias from "layouts/categorias";
import Historico from "layouts/historico";
import ValidacaoLoja from "layouts/validacaoLoja";
import LojasNaoAtivadas from "layouts/lojasNaoAtivadas"
import LojasNegadas from "layouts/lojasNegadas";
import VerDetalhes from "layouts/verDetalhesLoja";

// @mui icons
import Icon from "@mui/material/Icon";
import { Hidden } from "@mui/material";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Validar lojas",
    key: "ValidarLojas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ValidarLojas",
    component: <ValidarLojas />,
  },
  {
    type: "collapse",
    name: "Banners",
    key: "Banners",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/banners",
    component: <Banners />,
  },
  {
    type: "collapse",
    name: "Categorias",
    key: "category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categorias",
    component: <Categorias />,
  },
  {
    type: "collapse",
    name: "Histórico",
    key: "Histórico",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/historico",
    component: <Historico />,
  },
  // {
  //   type: "collapse",
  //   name: "Faturamento",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">payment</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "Lojas",
    key: "Lojas",
    icon: <Icon fontSize="small">stores</Icon>,
    route: "/lojas",
    component: <Lojas />,
  },
  {
    type: "collapse",
    name: "Lojas bloqueadas",
    key: "Lojas bloqueadas",
    icon: <Icon fontSize="small">blocked</Icon>,
    route: "/lojasBloqueadas",
    component: <LojasNaoAtivadas />,
  },
  {
    type: "collapse",
    name: "Lojas negadas",
    key: "Lojas negadas",
    icon: <Icon fontSize="small">warningreport</Icon>,
    route: "/lojasNegadas",
    component: <LojasNegadas />,
  },
  {
    type: "collapse",
    name: "Usuários",
    key: "Usuarios",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/usuarios",
    component: <Usuarios />,
  },
  {
    type: sessionStorage.getItem("tipoADM") === "MASTER" ? "collapse" : Hidden,
    name: "Cadastrar Admin",
    key: "cadastrarAdmin",
    icon: (
      <Icon fontSize="small" onClick={() => window.location.reload()}>
        person
      </Icon>
    ),
    route: "/cadastrarAdmin",
    component: <CadastrarAdmin />,
  },
  {
    type: "collapse",
    name: "Sair",
    key: "sign-in",
    icon: (
      <Icon fontSize="small" onClick={() => sessionStorage.remove("token")}>
        logout
      </Icon>
    ),
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "Validação de Lojas",
    key: "Validação de Lojas",
    icon: <Icon fontSize="small">check-in</Icon>,
    route: "/validacaoLoja/:id",
    type: Hidden,
    component: <ValidacaoLoja />,
  },
  {
    name: "Ver Detalhes",
    key: "Ver Detalhes",
    icon: <Icon fontSize="small">check-in</Icon>,
    route: "/verDetalhesLoja/:id",
    type: Hidden,
    component: <VerDetalhes />,
  },
];

export default routes;
