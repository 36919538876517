import React, { useState, useEffect } from "react";
import axiosToken from "../../../api/axiosToken";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Data({ adicionado }) {
  const [getData, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    prioridade: "",
    imagem: "",
    nome: "",
    data_expiracao: "",
    id: "",
  });
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  useEffect(() => {
    const resgatarBanners = async () => {
      await axiosToken
        .get(`${process.env.REACT_APP_API_URL}/admins/banners`)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          setShowError(true);
          setErrorMessage("Erro ao buscar banners", error.message);
        });
    };

    resgatarBanners();
  }, [getData && (adicionado || showSucess)]);

  const handleOpen = (banner) => {
    setValues({
      nome: banner.nome,
      prioridade: banner.prioridade,
      imagem: banner.imagem,
      dataExpiracao: banner.dataExpiracao,
      id: banner.id,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nome", values.nome);
    formData.append("prioridade", parseInt(values.prioridade, 10));
    formData.append("imagem", values.imagem);
    formData.append("dataExpiracao", values.dataExpiracao.replace("-", "/"));


    axiosToken
      .put(`/banners/${values.id}`, formData)
      .then(() => {
        setShowSucess(true);
        setSucessMessage("Banner atualizado com sucesso");
        handleClose();
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage("Erro no update do banner", error.message);
      });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosToken.delete(`banners/${id}`);
      setShowSucess(true);
      setSucessMessage("Deletado com sucesso", response.data);
    } catch (error) {
      setShowError(true);
      setErrorMessage("Erro ao deletar o banner", error.message);
    }
  };

  const banners = getData;

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = banners.map((banners) => {
    return {
      author: <Author image={banners.imagem} name={banners.nome} />,
      function: <Job title={banners.prioridade} />,
      status: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {banners.dataExpiracao}
        </MDTypography>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {banners.createdAt}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="nome"
                    value={values.nome}
                    onChange={(e) =>
                      setValues({ ...values, nome: e.target.value })
                    }
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Prioridade"
                    value={values.prioridade}
                    onChange={(e) =>
                      setValues({ ...values, prioridade: e.target.value })
                    }
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="file"
                    label=""
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setValues({ ...values, imagem: file });
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="date"
                    label="Data expiração"
                    value={values.dataExpiracao}
                    onChange={(e) =>
                      setValues({ ...values, dataExpiracao: e.target.value })
                    }
                    fullWidth
                  />
                </MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleUpdate}
                >
                  Editar
                </MDButton>
              </MDBox>
            </Box>
          </Modal>

          <MDButton
            color="success"
            variant="contained"
            onClick={() => handleOpen(banners)}
          >
            Editar
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => handleDelete(banners.id)}
          >
            Delete
          </MDButton>

          <MDSnackbar
            open={showError}
            title="ERROR"
            content={errorMessage}
            onClose={closeErrorNotification}
            close={closeErrorNotification}
            color="error"
            icon="warning"
          ></MDSnackbar>
          <MDSnackbar
            open={showSucess}
            title="OK!"
            content={sucessMessage}
            onClose={closeSucessNotification}
            close={closeSucessNotification}
            color="success"
            icon="warning"
          ></MDSnackbar>
        </MDTypography>
      ),
    };
  });

  const columns = [
    { Header: "Banner", accessor: "author", width: "30%", align: "left" },
    { Header: "Prioridade", accessor: "function", align: "center" },
    { Header: "Data expiração", accessor: "status", align: "center" },
    { Header: "Data de criação", accessor: "employed", align: "center" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}