// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosToken from "../../../api/axiosToken";

export default function Data() {
  const navigate = useNavigate();
  const goToValidationPage = (idLoja) => {
    navigate(`/validacaoLoja/${idLoja}`);
  };
  const [getData, setData] = useState([]);

  useEffect(() => {
    const resgatarLojas = async () => {
      await axiosToken
        .get("/admins/lojas/false")
        .then((res) => {
          setData(res.data);
          console.log(res);
        })
        .catch((error) => {
          console.error("Erro no fetch das lojas na home", error);
        });
    };

    resgatarLojas();
  }, []);

  const lojas = getData;

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = lojas
    .filter((lojaAtual) => !lojaAtual.ativa && !lojaAtual.negada)
    .map((loja) => {
      return {
        author: (
          <Author
            image={loja.imagem}
            name={loja.nomeResponsavel}
            email={loja.nomeFantasia}
          />
        ),
        function: <Job title={loja?.cnpj || loja?.cpf} />,
        status: (
          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {loja.categoria.nome}
          </MDTypography>
        ),
        employed: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {loja.numeroContato}
          </MDTypography>
        ),
        action: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <MDButton
              color="success"
              variant="contained"
              onClick={() => goToValidationPage(loja.id)}
            >
              Validar Loja
            </MDButton>
          </MDTypography>
        ),
      };
    });

  return {
    columns: [
      { Header: "loja", accessor: "author", width: "30%", align: "left" },
      { Header: "CNPJ/CPF", accessor: "function", align: "center" },
      { Header: "Categoria", accessor: "status", align: "center" },
      { Header: "Número", accessor: "employed", align: "center" },
      { Header: "Ação", accessor: "action", align: "center" },
    ],

    rows,
  };
}
