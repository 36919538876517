import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { useState, useEffect } from "react";
import axiosToken from "../../../api/axiosToken";

export default function Data() {
  const [getData, setData] = useState([]);

  useEffect(() => {
    const resgatarLojas = async () => {
      await axiosToken
        .get("/usuarios")
        .then((res) => {
          console.log(res.data);
          setData(res.data);
        })
        .catch((error) => {
          console.error("Erro no fetch dos usuarios", error);
        });
    };

    resgatarLojas();
  }, []);

  const user = getData;

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = user.map((user) => {
    return {
      author: (
        <Author
          image={user.imagem}
          name={user.nomeResponsavel}
          email={user.email}
        />
      ),
      function: <Job title={user.tipoCadastro} />,
      status: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {user.dataExpiracao}
        </MDTypography>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {user.createdAt}
        </MDTypography>
      ),
      // action: (
      //   <MDTypography
      //     component="a"
      //     href="#"
      //     variant="caption"
      //     color="text"
      //     fontWeight="medium"
      //   >
      //     <Button color="success" variant="contained">Bloquear</Button>
      //   </MDTypography>
      // ),
    };
  });

  return {
    columns: [
      { Header: "Usuario", accessor: "author", width: "30%", align: "left" },
      { Header: "Tipo cadastro", accessor: "function", align: "center" },
      { Header: "Data de criação", accessor: "employed", align: "center" },
      // { Header: "Ação", accessor: "action", align: "center" },
    ],

    rows,
  };
}
