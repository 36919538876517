import React, { useState, useEffect } from "react";
import axiosToken from "../../../api/axiosToken";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Data({ adicionado }) {
  const [getData, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    nome: "",
    imagem: "",
    id: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  useEffect(() => {
    const resgatarCategorias = async () => {
      await axiosToken
        .get("/admins/categorias")
        .then((res) => {
          setData(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          setShowError(true);
          setErrorMessage("Erro ao buscar categorias", error.message);
        });
    };

    resgatarCategorias();
  }, [getData && (adicionado || showSucess)]);

  const handleOpen = (categorias) => {
    setValues({
      nome: categorias.nome,
      imagem: categorias.imagem,
      id: categorias.id,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("nome", values.nome);
    formData.append("imagem", values.imagem);

    axiosToken
      .put(`/categorias/${values.id}`, formData)
      .then(() => {
        setShowSucess(true);
        setSucessMessage("Categoria atualizada com sucesso");
        handleClose();
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage("Erro no update da categoria", error.message);
      });
  };

  const handleDelete = async (id) => {
    try {
      await axiosToken.delete(`categorias/${id}`);
      setShowSucess(true);
      setSucessMessage("Categoria deletada com sucesso");
    } catch (error) {
      setShowError(true);
      setErrorMessage("Erro ao deletar da categoria", error.message);
    }
  };

  const categorias = getData;

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="md" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = categorias.map((categorias) => {
    return {
      author: (
        <Author
          image={categorias.imagem}
          name={categorias.nome}
          email={`Lojas cadastradas ${categorias.lojas}`}
        />
      ),
      function: <Job title={categorias.lojas} />,
      status: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {categorias.lojas}
        </MDTypography>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {categorias.createdAt}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <MDButton
            color="success"
            variant="contained"
            onClick={() => handleOpen(categorias)}
          >
            Editar
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => handleDelete(categorias.id)}
          >
            Delete
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="nome"
                    value={values.nome}
                    onChange={(e) =>
                      setValues({ ...values, nome: e.target.value })
                    }
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="file"
                    label=""
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setValues({ ...values, imagem: file });
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDButton
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={handleUpdate}
                >
                  Atualizar
                </MDButton>
              </MDBox>
            </Box>
          </Modal>
          <MDSnackbar
            open={showError}
            title="ERROR"
            content={errorMessage}
            onClose={closeErrorNotification}
            close={closeErrorNotification}
            color="error"
            icon="warning"
          ></MDSnackbar>
          <MDSnackbar
            open={showSucess}
            title="OK!"
            content={sucessMessage}
            onClose={closeSucessNotification}
            close={closeSucessNotification}
            color="success"
            icon="warning"
          ></MDSnackbar>
        </MDTypography>
      ),
    };
  });

  return {
    columns: [
      { Header: "Categoria", accessor: "author", width: "85%", align: "left" },
      { Header: "Ação", accessor: "action", align: "center" },
    ],

    rows,
  };
}
