import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { useState, useEffect } from 'react';
import api from "../../../api/axiosToken"


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export default function Data() {
  const [getData, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    adminEmail: "",
    adminNome: "",
    adminTipo: "",
    createdAt: "",
    id: "",
    mensagem: "",
    updatedAt: ""
  });

  const resgatarHistorico = async () => {
    await api.get("/admins/historico")
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((error) => {
        console.error("Erro no fetch do histórico", error);
      });
  };
  useEffect(() => {


    resgatarHistorico();
  }, []);

  const handleOpen = (admin) => {
    setValues({
      adminEmail: admin.adminEmail,
      adminNome: admin.adminNome,
      adminTipo: admin.adminTipo,
      createdAt: admin.createdAt,
      id: admin.id,
      mensagem: admin.mensagem,
      updatedAt: admin.updatedAt
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = getData.map((historyAction) => {
    return {
      author: (
        <Author
          name={historyAction.adminNome}
          email={historyAction.adminEmail}
        />
      ),
      function: <Job title={historyAction.adminTipo} />,
      status: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {historyAction.createdAt}
        </MDTypography>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {historyAction.createdAt}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nome"
                    variant="standard"
                    value={values.adminNome}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Email"
                    variant="standard"
                    value={values.adminEmail}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Tipo"
                    variant="standard"
                    value={values.adminTipo}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Data"
                    variant="standard"
                    value={values.createdAt}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Mensagem"
                    // id="inputImagem"
                    variant="standard"
                    value={values.mensagem}
                    fullWidth
                  />
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="updatedAt"
                    variant="standard"
                    value={values.updatedAt}
                    fullWidth
                  />
                </MDBox> */}
              </MDBox>
            </Box>
          </Modal>
          <MDButton
            color="success"
            variant="contained"
            onClick={() => handleOpen(historyAction)}
          >
            Ver Detalhes
          </MDButton>

        </MDTypography>
      ),
    };
  });

  return {
    columns: [
      { Header: "Usuario", accessor: "author", width: "30%", align: "left" },
      { Header: "Tipo de usuário", accessor: "function", align: "center" },
      { Header: "Data", accessor: "employed", align: "center" },
      { Header: "Ação", accessor: "action", align: "center" },
    ],

    rows
  };
}
