import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosToken from "api/axiosToken";
import MDSnackbar from "components/MDSnackbar";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function VerDetalhes() {
  const navigate = useNavigate();
  const urlParameters = useParams();
  useEffect(() => {
    getStoreInformation();
  }, []);
  const [values, setValues] = useState({
    razaoSocial: "",
    nomeFantasia: "",
    nomeResponsavel: "",
    numeroContato: "",
    inscricaoEstadual: "",
    imagem: "",
    categoria: {},
    endereco: {},
    categoriaId: "",
    regraDePontos: "",
    documentoComprovacao: "",
    latitude: "",
    longitude: "",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  const getStoreInformation = () => {
    axiosToken.get(`/lojas/${urlParameters.id}`).then((res) => {
      // console.log(res.data)
      setValues({ ...res.data });
    });
  };

  const lojaValidada = (e) => {
    e.preventDefault();
    axiosToken
      .put(`admins/ativarloja/${urlParameters.id}`)
      .then((res) => {
        setShowSucess(true);
        setSucessMessage("Loja validada com sucesso");
        navigate("/ValidarLojas");
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage("Erro ao validar loja", err.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br></br>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Ver Detalhes
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="RazaoSocial"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                value={values.razaoSocial}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="nomeFantasia"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.nomeFantasia}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="cnpj"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.cnpj}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="nomeResponsavel"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.nomeResponsavel}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="numeroContato"
                variant="standard"
                value={values.numeroContato}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="inscricaoEstadual"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.inscricaoEstadual}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <a href={values.imagem} download target="_blank">
                Visualizar Logo
              </a>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="categoriaID"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.categoria ? values.categoria.id : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="regraDePontos"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.regraDePontos}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <a href={values.documentoComprovacao} download target="_blank">
                Visualizar Documento
              </a>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="latitude"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.latitude : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="longitude"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.longitude : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="cep"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.cep : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="logradouro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.logradouro : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="complemento"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.complemento : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="bairro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.bairro : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="cidade"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.cidade : ""}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="uf"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={values.endereco ? values.endereco.uf : ""}
                fullWidth
              />
            </MDBox>
            {/* <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={lojaValidada}
                fullWidth
              >
                Validar
              </MDButton>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              {/* <MDButton
                variant="gradient"
                color="error"
                // onClick={handleSubmit}
                fullWidth
              >
                Negar Validação
              </MDButton> */}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center"></MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={showError}
        title="ERROR"
        content={errorMessage}
        onClose={closeErrorNotification}
        close={closeErrorNotification}
        color="error"
        icon="warning"
      ></MDSnackbar>
      <MDSnackbar
        open={showSucess}
        title="OK!"
        content={sucessMessage}
        onClose={closeSucessNotification}
        close={closeSucessNotification}
        color="success"
        icon="warning"
      ></MDSnackbar>
    </DashboardLayout>
  );
}

export default VerDetalhes;
