// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import Button from '@mui/material/Button';
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";


import { useState, useEffect, } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axiosToken from "../../../api/axiosToken"
import Button from "@mui/material/Button";




export default function Data() {
  const [getData, setData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");
  const navigate = useNavigate();
  const goToValidationPage = (idLoja) => {
    console.log(idLoja);
    navigate(`/verDetalhesLoja/${idLoja}`);
    // ,{params}
  }

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  }

  const resgatarLojas = async () => {
    await axiosToken.get("/admins/lojas/true")
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((error) => {
        console.error("Erro no fetch das lojas na home", error);
      });
  };
  useEffect(() => {


    resgatarLojas();
  }, [getData && showSucess]);

  const handleBlock = async (id) => {
    await axiosToken
      .put(`/admins/bloquearloja/${id}`)
      .then((res) => {
        setShowSucess(true);
        console.log(res.data.message);
        setSucessMessage("Loja bloqueada com sucesso", res.data.message);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage("Erro ao bloquear loja", error.message);
      });
  };

  const lojas = getData;



  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = lojas.filter((loja) => !loja.dataBloqueio)
  .map((loja) => {
    return {
      author: (
        <Author
          image={loja.imagem}
          name={loja.nomeResponsavel}
          email={loja.nomeFantasia}
        />
      ),
      function: <Job title={loja.cnpj} />,
      status: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {loja.categoria.nome}
        </MDTypography>
      ),
      employed: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {loja.numeroContato}
        </MDTypography>
      ),
      validado: (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {loja.dataAtivacao}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <MDButton variant="gradient" color="success" onClick={()=>goToValidationPage(loja.id)}>
            Ver detalhes
          </MDButton>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <MDButton
              variant="gradient"
              color="primary"
              onClick={() => handleBlock(loja.id)}
            >
              Bloquear
            </MDButton>
          </MDTypography>
          <MDSnackbar
            open={showError}
            title="ERROR"
            content={errorMessage}
            onClose={closeErrorNotification}
            close={closeErrorNotification}
            color="error"
            icon="warning"
          ></MDSnackbar>
          <MDSnackbar
            open={showSucess}
            title="OK!"
            content={sucessMessage}
            onClose={closeSucessNotification}
            close={closeSucessNotification}
            color="success"
            icon="warning"
          ></MDSnackbar>
        </MDTypography>
      ),
    };
  });

  return {
    columns: [
      { Header: "loja", accessor: "author", width: "30%", align: "left" },
      { Header: "CNPJ", accessor: "function", align: "center" },
      { Header: "Categoria", accessor: "status", align: "center" },
      { Header: "Número", accessor: "employed", align: "center" },
      { Header: "Data validado", accessor: "validado", align: "center" },
      { Header: "Ação", accessor: "action", align: "center" },
    ],

    rows,
  };
}
