// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import React, { useState, useEffect } from "react";
import axios from "axios";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import axiosToken from "api/axiosToken";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [getBanners, setBanners] = useState([]);
  const [getUsers, setUsers] = useState([]);
  const [getLojasValidas, setLojasValidas] = useState([]);
  const [getLojas, setLojas] = useState([]);
  const [getCategorias, setCategorias] = useState([]);
  const [getLojasBarras, setLojasBarras] = useState({});
  const [getUsuariosBarras, setUsuariosBarras] = useState({});
  const [getBannersBarras, setBannersBarras] = useState({});

  function estatisticaLojas(params) {
    const lojasPorMes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    params.forEach((element) => {
      const mes = parseInt(element.createdAt.slice(3, 5)) - 1;
      lojasPorMes[mes]++;
    });
    setLojasBarras({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dec",
      ],
      datasets: { label: "Lojas", data: lojasPorMes },
    });
  }

  function estatisticaUsuarios(params) {
    const usuariosPorMes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    params.forEach((element) => {
      const mes = parseInt(element.createdAt.slice(3, 5)) - 1;
      usuariosPorMes[mes]++;
    });
    setUsuariosBarras({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dec",
      ],
      datasets: { label: "Usuarios", data: usuariosPorMes },
    });
  }

  function estatisticaBanners(params) {
    const banners = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    params.forEach((element) => {
      const mes = parseInt(element.createdAt.slice(3, 5)) - 1;
      banners[mes]++;
    });
    setBannersBarras({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dec",
      ],
      datasets: { label: "Usuarios", data: banners },
    });
  }

  useEffect(() => {
    const resgatarBanners = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/banners`)
        .then((res) => {
          setBanners(res.data);
          estatisticaBanners(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    };

    const resgatarLojasNValidas = async () => {
      await axiosToken
        .get("/admins/lojas/false")
        .then((res) => {
          setLojas(res.data);
        })
        .catch((error) => {
          console.error("Erro no fetch das lojas na home", error);
        });
    };

    const resgatarLojas = async () => {
      await axiosToken
        .get("/admins/lojas/true")
        .then((res) => {
          setLojasValidas(res.data);
          estatisticaLojas(res.data);
        })
        .catch((error) => {
          console.error("Erro no fetch das lojas na home", error);
        });
    };

    const resgatarCategorias = async () => {
      await axiosToken
        .get("/categorias")
        .then((res) => {
          setCategorias(res.data);
        })
        .catch((error) => {
          console.error("Erro no fetch das lojas na home", error);
        });
    };

    const resgatarUsers = async () => {
      await axiosToken
        .get("/usuarios")
        .then((res) => {
          setUsers(res.data);
          estatisticaUsuarios(res.data);
        })
        .catch((error) => {
          console.error("Erro no fetch dos usuarios", error);
        });
    };

    resgatarUsers();
    resgatarCategorias();
    resgatarLojasNValidas();
    resgatarLojas();
    resgatarBanners();
  }, []);

  function countBanners() {
    return getBanners.length;
  }
  function countLojasNValidas() {
    return getLojas.length;
  }
  function countLojas() {
    return getLojasValidas.length;
  }
  function countCategorias() {
    return getCategorias.length;
  }
  function countUsers() {
    return getUsers.length;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Banners"
                count={countBanners()}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Lojas a validar"
                count={countLojasNValidas()}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Lojas"
                count={countLojas()}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="category"
                title="Categorias"
                count={countCategorias()}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Usuários"
                count={countUsers()}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Lojas"
                  date="Hoje"
                  chart={getLojasBarras}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Usuários"
                  date="Hoje"
                  chart={getUsuariosBarras}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Banners"
                  date="Hoje"
                  chart={getBannersBarras}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
