import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: sessionStorage.getItem("token"),
    // 'Content-Type': 'multipart/form-data'
  },
});

export default api;
