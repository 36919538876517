// @mui material components
import React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import axiosToken from "../../api/axiosToken";
// Data
import categoriaTableData from "layouts/categorias/data/categoriaTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Tables() {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  let adicionado = showSucess;
  const [sucessMessage, setSucessMessage] = useState("");
  const { columns, rows } = categoriaTableData({ adicionado });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchQuery, setSearchQuery] = useState("");

  function search(rows) {
    return rows.filter((row) =>
      row.author.props.name.toLowerCase().includes(searchQuery)
    );
  }
  

  const [values, setValues] = useState({
    nome: "",
    imagem: "",
  });

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  const handleSubmit = async (e) => {
    const imageInput = document.getElementById("inputImagem");
    const formData = new FormData();
    e.preventDefault();
    formData.append("nome", values.nome);
    formData.append("imagem", imageInput.files[0]);

    axiosToken
      .post("/categorias", formData)
      .then((res) => {
        setShowSucess(true);
        setSucessMessage("Categoria adicionada com sucesso");
        handleClose();
      })
      .catch((err) => {
        setShowError(true);
        console.log(values);
        setErrorMessage("Erro ao adicionar categorias", err.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                onChange={(e) => setValues({ ...values, nome: e.target.value })}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="file"
                label=""
                id="inputImagem"
                onChange={(e) =>
                  setValues({ ...values, imagem: e.target.value })
                }
                fullWidth
              />
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleSubmit}
            >
              Adicionar
            </MDButton>
          </MDBox>
        </Box>
      </Modal>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Categorias{" "}
                  <MDInput
                    style={{
                      marginLeft: "2%",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                    type="text"
                    label="Pesquisar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                  />
                  <MDButton
                    style={{ marginLeft: "67%" }}
                    color="success"
                    variant="contained"
                    onClick={handleOpen}
                  >
                    Adicionar
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: search(rows) }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        open={showError}
        title="ERROR"
        content={errorMessage}
        onClose={closeErrorNotification}
        close={closeErrorNotification}
        color="error"
        icon="warning"
      ></MDSnackbar>
      <MDSnackbar
        open={showSucess}
        title="OK!"
        content={sucessMessage}
        onClose={closeSucessNotification}
        close={closeSucessNotification}
        color="success"
        icon="warning"
      ></MDSnackbar>
    </DashboardLayout>
  );
}

export default Tables;
