import { useState } from "react";
import axiosToken from "../../../api/axiosToken";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/logo.png";

function SignIn() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    senha: "",
  });

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosToken
      .post("/admins/login", {
        email: values.email,
        senha: values.senha,
      })
      .then((res) => {
        sessionStorage.setItem("token", `Bearer ${res.data.token}`);
        sessionStorage.setItem("tipoADM", res.data.tipo);
      })
      .then(() => {
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage("Erro ao fazer login", err.message);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="white"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <img src={logo} height="194px" width="200px"></img>
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                onChange={(e) =>
                  setValues({ ...values, senha: e.target.value })
                }
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={showError}
        title="ERRO"
        content={errorMessage}
        onClose={closeErrorNotification}
        close={closeErrorNotification}
        color="error"
        icon="warning"
      ></MDSnackbar>
    </BasicLayout>
  );
}

export default SignIn;
