import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosToken from "api/axiosToken";
import MDSnackbar from "components/MDSnackbar";

function CadastrarAdmin() {
  const [values, setValues] = useState({
    nome: "",
    email: "",
    senha: "",
  });

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");

  const closeErrorNotification = () => {
    setShowError(false);
    setErrorMessage("");
  };

  const closeSucessNotification = () => {
    setShowSucess(false);
    setShowSucess("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    axiosToken
      .post("/admins", {
        nome: values.nome,
        email: values.email,
        senha: values.senha,
      })
      .then((res) => {
        setShowSucess(true);
        setSucessMessage("Adicionado com sucesso", res.data);
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage("Erro ao adicionar o funcionario", err.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br></br>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Cadastro de Funcionarios
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                onChange={(e) => setValues({ ...values, nome: e.target.value })}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                variant="standard"
                onChange={(e) =>
                  setValues({ ...values, senha: e.target.value })
                }
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                fullWidth
              >
                Cadastrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center"></MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        open={showError}
        title="ERROR"
        content={errorMessage}
        onClose={closeErrorNotification}
        close={closeErrorNotification}
        color="error"
        icon="warning"
      ></MDSnackbar>
      <MDSnackbar
        open={showSucess}
        title="OK!"
        content={sucessMessage}
        onClose={closeSucessNotification}
        close={closeSucessNotification}
        color="success"
        icon="warning"
      ></MDSnackbar>
    </DashboardLayout>
  );
}

export default CadastrarAdmin;
